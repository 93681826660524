// extracted by mini-css-extract-plugin
export var Container = "Q_cv";
export var aniBgLoading = "Q_f";
export var aniSpin = "Q_d";
export var gridColD1 = "Q_cy";
export var gridColD10 = "Q_cK";
export var gridColD11 = "Q_cL";
export var gridColD12 = "Q_cM";
export var gridColD2 = "Q_cz";
export var gridColD3 = "Q_cB";
export var gridColD4 = "Q_cC";
export var gridColD5 = "Q_cD";
export var gridColD6 = "Q_cF";
export var gridColD7 = "Q_cG";
export var gridColD8 = "Q_cH";
export var gridColD9 = "Q_cJ";
export var gridColM1 = "Q_cN";
export var gridColM2 = "Q_cP";
export var gridRowD = "Q_cw";
export var gridRowM = "Q_cx";