// extracted by mini-css-extract-plugin
export var Footer = "S_c1";
export var aniBgLoading = "S_f";
export var aniSpin = "S_d";
export var column = "S_bz";
export var columns = "S_c4";
export var heading = "S_C";
export var link = "S_bB";
export var main = "S_c";
export var nav = "S_by";
export var newsletter = "S_c5";
export var newsletter__heading = "S_c6";
export var scrollTop = "S_c2";
export var scrollTop__btn = "S_c3";