// extracted by mini-css-extract-plugin
export var WelcomeOverlay = "V_dl";
export var aniBgLoading = "V_f";
export var aniSpin = "V_d";
export var bg = "V_Y";
export var body = "V_br";
export var bodyText = "V_dq";
export var close = "V_dn";
export var closeButton = "V_dr";
export var grid = "V_F";
export var head = "V_dp";
export var header = "V_B";
export var header__inner = "V_bp";
export var heading = "V_C";
export var inner = "V_k";
export var scroll = "V_dm";